import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import useMouseLeave from 'use-mouse-leave';

import { UilFacebookF } from '@iconscout/react-unicons';
import { UilInstagram } from '@iconscout/react-unicons';
import { UilTwitterAlt } from '@iconscout/react-unicons';

import QuestionCard from '../components/QuestionCard';
import Help from '../components/Help';
import LoadingIcon from '../components/LoadingIcon';
import Logo from '../components/Logo';
import StackedCards from '../components/StackedCards';

import './styles/HomePage.scss';

const NUM_QUESTIONS = 10;

// map is used to determine what the background color of the page
const backgroundColorMap = {
    0: '#7875FB',
    1: '#86AEBB',
    2: '#4E65E0',
    3: '#D17440',
    4: '#7E4157',
    5: '#283153',
    6: '#8061D9',
    7: '#459464',
    8: '#D95336',
    9: '#1D1212',
};

//map is used to determine the color of the card 
const cardColorMap = {
    0: '#DCFFF7',
    1: '#E5FFAD',
    2: '#FFD6F3',
    3: '#FFEADE',
    4: '#DBD6FF',
    5: '#D7FFCD',
    6: '#FFD2B9',
    7: '#C2FBFF',
    8: '#F2BDFF',
    9: 'linear-gradient(#D5FFA0, #96FFDF)',
};

const GAME_STATUS = {
    NOT_STARTED: 'Not started',
    IS_TUTORIAL: 'Is tutorial',
    IS_PLAYING: 'Is playing',
    FINISHED: 'Finished',
};
const data = [
    ["If you could invent a new holiday, what would it be?", "Sweet, savory, or spicy - which one do you prefer?", "What's your spirit animal?", "Who do you think is the better dancer here?", "Did you have a childhood nickname? If so, what was the story behind it?", "Do you prefer the city or countryside?", "What's a useless talent of yours?", "If you could only eat one food for the rest of your life, what would it be?", "Out of your five senses, which one is your strongest?", "On a count to 3, point to the person who is the most likely to fart in public and casually brush it off.", "Name 3 traits that we have in common.", "What's your funniest bad haircut story?", "If you can choose your own name, what would it be?", "What's the weirdest fact you know?", "Pitch your funniest app idea. Give each other a few minutes to think. ", "If you could be an Avenger, which one would you be?"],
    ["Who is someone famous you'd like to have dinner with? ", "If you were to shadow anyone in the world for a day, who would it be?  ", "What is your favorite place on Earth?", "Who do you look up to for inspiration?", "What was the best thing about being a kid?", "Where is your happy place? ", "Name a movie that inspired you, changed your perspective, or you just liked a lot. ", "If you had a theme song, what song would it be?", "What's your favourite TV show?", "If you could bring one memento to a deserted island, what would it be?", "What was one trait you admired about your younger self?", "What's your guilty pleasure?", "What was the favourite thing about your childhood?", "What food reminds you of your childhood? ", "Who was your childhood hero, fictional or not?", "What were you like as a 10 year old kid? Describe your young self."],
    ["What makes you nervous?", "What is one word that best describes you?", "What is something people wouldn't guess about you?", "Are you a pessimist or optimist?", "What are you grateful for?", "What is the happiest moment of your life thus far?", "What are you known for amongst your friends or colleagues?", "What is the hardest thing for people to understand about you?", "If you were to change anything about yourself, what would you change?", "Humility aside, what do you think is the most attractive or cool thing about you? ", "What is your biggest passion?", "What do people assume about you based on first impressions?", "Which fictional character best represents you?", "How do you decompress after a long day of work?", "What's something childish that you still love to do?", "What was the last thing you bought that made you super happy?"],
    ["What is a skill or talent you want to master?", "If you were to start a podcast, what would you talk about? Assuming you don't already have one. ", "If you were a superhero, what would be your superpower?", "What is something on your bucket list that you have yet to cross off?", "Whether you want or have kids or not, what type of parent do you think you will be?", "In your opinion, what has been your greatest accomplishment so far?", "If you had three wishes, what would they be? Why?", "If money wasn't an issue, what would you be doing right now?", "How do you envision yourself in your 50's?", "Describe your perfect retirement. What would your day be like? Where will you be? ", "How do you think you'll be different from your parents?", "What do you hope to accomplish in the next 5 years?", "What motivates you to do your job everyday?", "Would you rather be loved or respected?"],
    ["What's one of your weaknesses?", "What's your biggest pet peeve?", "Have you ever gotten your heart broken? ", "What's something you regret giving up on in the past?", "What are you worried about not achieving?", "How has your idea of love changed over the past years?", "In your opinion, what's something annoying about you?", "Do you have an irrational fear? If so, what is it?", "What is something that is bothering you right now?", "What do you do when you're feeling lonely?", "What scares you the most about growing old?", "Do you feel like anything is missing in your life right now?", "How do you think you've changed from when you were little?", "What makes you angry to the bone?", "What is something you fear? And why?", "What do you think is the toughest thing about being an adult?"],
    ["Do you believe in destiny? ", "What do you think the world will be like 20 years from now?", "Would you rather have loved and lost, or never to have loved at all?", "Do you believe in free will?", "Do you think other lifeforms exist out there?", "Do you think people are intrisically good or evil?", "What's your definition of evil?", "Would the world be a better or worse place if everyone looked the same?", "Do you think it's possible to time travel?", "What you do you think is the most tragic way to die?", "Do you believe in soulmates?", "What is happiness to you?", "Do you believe in life after death?", "If you had all the money and influence, what is something in the world you want to fix?"],		
    ["Who would you say was a significant part of helping you get to where you are today?  ", "What's something that people do that makes you smile?", "What's the most meaningful gift you've received?", "What's something you appreciate about life?", "Is there a teacher that you wish you could call up and thank?", "What will instantly draw you to someone?", "What defines a good friendship?", "What do you think people appreciate and value the most about you? ", "What's one thing that someone has done that made a positive impact in your life?"],						
    ["What is something that you have done for someone that you are incredibly proud of? ", "If you were to start a charity, what would be the cause?", "What are you thankful for?", "How do you define love? ", "What would you hope to for your loved ones that you don't have the resources to do right now?", "Have you ever sacrificed something for someone you loved?", "What's your top love language?", "What is the nicest thing someone has done for you?", "What's the most thoughtful gift you've given someone?"],
    ["What do you think your purpose in life is?", "Where does your self-worth come from? ", "Is there a life motto or memorable quote that you live by? What is it? ", "If you had a crystal ball, what would you want to know?", "When was the last time you were disappointed in yourself?", "How do you want to contribute back to society?", "If the world ended tomorrow, what would you do on your last day?", "What are you most ashamed of?", "What's your biggest regret?", "On a scale of 1-10, how satisfied are you with life right now? Why?"],					
    ["In what ways have you grown over the course of your life?", "Do you think your parents and loved ones are proud of where you are in life?", "In your opinion, how can someone find true happiness? ", "How much does luck and hardwork play into where you are today? ", "How do you want to be remembered?", "For everyone in the room, name one thing about them that you love. ", "Describe your ideal life 10 years from now. Where are you? What are you doing? Give details.", "What would you tell your 10 year old self?", "What legacy would you want to leave this world with?"]							
];

const HomePage = (props) => {
    const [questionsMap, setQuestionsMap] = useState([]);
    const [gameStatus, setGameStatus] = useState(GAME_STATUS.NOT_STARTED);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [isSlideLeftAnimationVisible, setIsSlideLeftAnimationVisible] = useState(false);
    const [isSlideRightAnimationVisible, setIsSlideRightAnimationVisible] = useState(false);
    const currentLevelRef = useRef(currentLevel);

    const [backMouseLeft, backRef] = useMouseLeave();
    const [nextMouseLeft, nextRef] = useMouseLeave();

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [isHoveringStart, setIsHoveringStart] = useState(false);
    const [isHoveringNext, setIsHoveringNext] = useState(false);
    const [isHoveringBack, setIsHoveringBack] = useState(false);

    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    const fetchQuestions = (use_api = false) => {
        if (use_api) {
            const url = 'https://convos-api-bloom.herokuapp.com/questions';
            return fetch(url).then(data => data.json());
        }
        return new Promise((func, _) => {
            func(data);
        });
    };

    useEffect(() => {
        let mounting = true;
        fetchQuestions().then((questions) => {
            if (mounting) {
                setQuestionsMap(questions);
            }
            const { currentLevel } = queryString.parse(props.location.search);
            const levels = Object.keys(questions).map(level => `${Number(level) + 1}`);


            if (levels.includes(currentLevel)) {
                setGameStatus(GAME_STATUS.IS_PLAYING);
                setCurrentLevel(Number(currentLevel - 1));
            }
        });

        return () => { mounting = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (backMouseLeft) {
            setIsHoveringBack(false);
        } else {
            setIsHoveringBack(true);
        }
    }, [backMouseLeft]);


    useEffect(() => {
        if (nextMouseLeft) {
            setIsHoveringNext(false);
        } else {
            setIsHoveringNext(true);
        }
    }, [nextMouseLeft]);

    useEffect(() => {
        switch (gameStatus) {
            case GAME_STATUS.IS_TUTORIAL:
                window.gtag('event', 'page_view', { page_title: 'Tutorial', page_path: '/tutorial' });
                return;
            case GAME_STATUS.IS_PLAYING:
                const question_number = currentLevel + 1;
                window.gtag('event', 'page_view', { page_title: 'Question #' + question_number, page_path: '/questions?id=' + question_number });
                return;
            case GAME_STATUS.FINISHED:
                window.gtag('event', 'page_view', { page_title: 'Finished' + question_number, page_path: '/complete' });
                return;
            case GAME_STATUS.NOT_STARTED:
                window.gtag('event', 'page_view', { page_title: 'Not Started', page_path: '/not_started' });
                return;
            default:
                return;
        }
    }, [gameStatus, currentLevel]);

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 225 && touchEnd !== 0) {
            handleNext();
            setTouchStart(0);
            setTouchEnd(0);
        }

        if (touchStart - touchEnd < -225 && touchEnd !== 0) {
            handleBack();
            setTouchStart(0);
            setTouchEnd(0);
        }
    };

    const isMobile = window.innerWidth < 600;

    const handleStartTutorial = async () => {
        window.gtag('event', 'engagement', {
            'event_category' : 'App Events',
            'event_label' : 'Start Tutorial'
        });
        setGameStatus(GAME_STATUS.IS_TUTORIAL);
        await handleSwap();
    };

    const handleBeginGame = () => {
        window.gtag('event', 'engagement', {
            'event_category' : 'App Events',
            'event_label' : 'Begin Game'
        });
        setGameStatus(GAME_STATUS.IS_PLAYING);
    };

    const handleBack = async () => {
        window.gtag('event', 'engagement', {
            'event_category' : 'App Events',
            'event_label' : 'Back',
            'event_value': currentLevel + 1
        });
        if (currentLevel > 0) {
            setIsSlideRightAnimationVisible(true);
            currentLevelRef.current = currentLevel;
            await setTimeout(() => {
                setIsSlideRightAnimationVisible(false);
            }, 1000);

            await setTimeout(() => {
                setCurrentLevel(currentLevel => currentLevel - 1);
            }, 1000);
        }
    };

    const handleNext = async () => {
        window.gtag('event', 'engagement', {
            'event_category' : 'App Events',
            'event_label' : 'Next',
            'event_value': currentLevel + 1
        });
        if (currentLevel >= NUM_QUESTIONS - 1) {
            setGameStatus(GAME_STATUS.FINISHED);
            setIsHoveringBack(false);
            setIsHoveringNext(false);
        } else {
            setIsSlideLeftAnimationVisible(true);
            currentLevelRef.current = currentLevel;
            await setTimeout(() => {
                setIsSlideLeftAnimationVisible(false);
            }, 1500);

            await setTimeout(() => {
                setCurrentLevel(currentLevel => currentLevel + 1);
                setCurrentQuestionIndex(0);
            }, 1500);
        }
    };

    const handleSwap = async () => {
        if (gameStatus === GAME_STATUS.IS_PLAYING) {
            window.gtag('event', 'engagement', {
                'event_category' : 'App Events',
                'event_label' : 'Swap',
                'event_value': currentLevel + 1
            });
        }
        // get a random index for the available questions in the current level
        const questionsForCurrentLevel = questionsMap[currentLevel] ?? [];

        // make sure that we are setting a new index
        let newQuestionIndex = currentQuestionIndex;
        while (newQuestionIndex === currentQuestionIndex && questionsForCurrentLevel.length !== 1) {
            newQuestionIndex = Math.floor(Math.random() * questionsForCurrentLevel.length);
        };
        await setTimeout(() => {
            setCurrentQuestionIndex(newQuestionIndex);
        }, 500);
    };

    const handleMouseEnter = () => setIsHoveringStart(true);

    const handleMouseLeave = () => setIsHoveringStart(false);

    let questionCardAnimationClass = '';
    let bottomQuestionCardAnimationClass = '';
    if (isSlideLeftAnimationVisible) {
        questionCardAnimationClass = 'slideLeft';
        bottomQuestionCardAnimationClass = 'expandCard';
    }

    // cases where we show loading icon
    const questionsNotLoaded = questionsMap.length === 0;;
    const isReplaying = gameStatus.NOT_STARTED && queryString.parse(props.location.search).currentLevel;
    if (questionsNotLoaded || isReplaying) {
        return (
            <div className="HomePage" style={{ 'backgroundColor': '#4E65E0' }}>
                <LoadingIcon />
            </div>
        );
    };

    const renderWebButtons = () => {
        return (
            <div className="buttons">
                {
                    currentLevel > 0
                        ? <button
                            className="back"
                            disabled={isSlideRightAnimationVisible}
                            onClick={handleBack}
                            onMouseEnter={() => setIsHoveringBack(true)}
                            ref={backRef}
                        >
                            Previous level
                        </button>
                        : <button className="back" style={{ opacity: '0' }}>Previous level</button>
                }
                <button
                    className="next"
                    disabled={isSlideLeftAnimationVisible}
                    onClick={handleNext}
                    onMouseEnter={() => setIsHoveringNext(true)}
                    ref={nextRef}
                >
                    {currentLevel !== 9 ? 'Next level' : 'Finish'}
                </button>
            </div>
        );
    };

    const renderMobileButtons = () => {
        return (
            <div className="mobile-buttons">
                {
                    currentLevel > 0
                        ? <button
                            className="back"
                            disabled={isSlideRightAnimationVisible}
                            onClick={handleBack}
                            onMouseEnter={() => setIsHoveringBack(true)}
                            onMouseLeave={() => setIsHoveringBack(false)}
                        >
                            Previous level
                        </button>
                        : <button className="back" style={{ opacity: '0' }}>Previous level</button>
                }
                <button
                    className="next"
                    disabled={isSlideLeftAnimationVisible}
                    onClick={handleNext}
                    onMouseEnter={() => setIsHoveringNext(true)}
                    onMouseLeave={() => setIsHoveringNext(false)}
                >
                    {currentLevel !== 9 ? 'Next level' : 'Finish'}
                </button>
            </div>
        );
    };


    return (
        <div className="HomePage" style={{ 'backgroundColor': gameStatus === GAME_STATUS.IS_PLAYING ? backgroundColorMap[currentLevel] : '#4E65E0' }}>
            {
                !isMobile && <div className={classNames("left-overlay", { darken: isHoveringBack })} />
            }
            {
                !isMobile && <div className={classNames("right-overlay", { darken: isHoveringNext })} />
            }
            <div className="header">
                <Logo />
                <Help />
            </div>
            {gameStatus === GAME_STATUS.NOT_STARTED && !isMobile && <div className="left-graphic" />}
            {gameStatus === GAME_STATUS.NOT_STARTED && !isMobile && <div className="right-graphic" />}
            {gameStatus === GAME_STATUS.NOT_STARTED && <div className={classNames({
                'fadeIn': gameStatus === GAME_STATUS.NOT_STARTED,
                'fadeOut': gameStatus !== GAME_STATUS.NOT_STARTED,
            })} style={{
                display: 'flex',
                'flexDirection': 'column',
                'justifyContent': 'center',
                'alignItems': 'center',
            }}>
                <p className="intro fadeIn">
                    <span style={{ color: '#FFC2F5' }}>Convos</span> is a card game designed to help people create meaningful connections through quirky and thoughtful questions.
                </p>
                <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <button className="start" onClick={handleStartTutorial}>
                        <span className="start-text">Start the game</span>
                    </button>
                </div>
                <div className={classNames("start-card-2", { 'is-hovered': isHoveringStart })}>
                    <QuestionCard
                        color={'#FFE0CA'}
                        isTooltipHidden
                        levelText={currentLevel}
                        questionText={'What is one of your most defining traits?'}
                    />
                </div>
                <div className={classNames("start-card-3", { 'is-hovered': isHoveringStart })}>
                    <QuestionCard
                        color={'#D6E9FF'}
                        isTooltipHidden
                        levelText={currentLevel}
                        questionText={'What are you the most proud of?'}
                    />
                </div>
                <div className={classNames("start-card-1", { 'is-hovered': isHoveringStart })}>
                    <QuestionCard
                        color={'#FFD6F3'}
                        isTooltipHidden
                        levelText={currentLevel}
                        questionText={'What was your most embarassing moment?'}
                    />
                </div>
                {
                    isMobile && <div className="stacked-cards" ><StackedCards /></div>
                }
            </div>}

            {gameStatus === GAME_STATUS.IS_TUTORIAL && <div className={classNames("TutorialContent", {
                'is-tutorial': gameStatus === GAME_STATUS.IS_TUTORIAL,
                'fadeIn': gameStatus === GAME_STATUS.IS_TUTORIAL,
                'fadeOut': gameStatus !== GAME_STATUS.IS_TUTORIAL,
            })}>
                <div className="card">
                    <div className="image" />
                    <div className="content">
                        <p className="title">How to play</p>
                        <p className="text">10 questions, 10 levels of intimacy.</p>
                        <p className="text">With a partner, friends, or family, take turns reading each question aloud and have everyone answer.</p>
                        <p className="text">You can tap on a card to swap a question at each level, but use this sparingly. Have a good conversation!</p>
                    </div>
                </div>
                <button className="button" onClick={handleBeginGame}>
                    <span>Let's go!</span>
                </button>
            </div>}
            <div>
                {
                    gameStatus === GAME_STATUS.IS_PLAYING &&
                    <div className={classNames('cards-container', {
                        'fadeIn': gameStatus === GAME_STATUS.IS_PLAYING,
                        'fadeOut': gameStatus !== GAME_STATUS.IS_PLAYING,
                    })}>
                        <div style={{ display: 'grid' }}>
                            {
                                currentLevel < NUM_QUESTIONS - 1 &&
                                    <div className={bottomQuestionCardAnimationClass} style={{
                                    'gridRow': '1',
                                    'gridColumn': '1',
                                }}>
                                    <QuestionCard
                                        color={cardColorMap[currentLevel + 1]}
                                        isTooltipHidden
                                        levelText={currentLevel + 2}
                                        questionText={questionsMap[currentLevel + 1][currentQuestionIndex]}
                                    />
                                </div>
                            }
                            <div className={questionCardAnimationClass} style={{
                                'gridRow': '1',
                                'gridColumn': '1',
                            }}>
                                <QuestionCard
                                    color={cardColorMap[currentLevel]}
                                    isTooltipHidden={false}
                                    levelText={currentLevel + 1}
                                    onSwapClick={handleSwap}
                                    onTouchEnd={handleTouchEnd}
                                    onTouchMove={handleTouchMove}
                                    onTouchStart={handleTouchStart}
                                    questionText={questionsMap[currentLevel][currentQuestionIndex]}
                                />
                            </div>
                        </div>
                    </div>
                }
                {gameStatus === GAME_STATUS.IS_PLAYING && !isMobile && renderWebButtons()}
                {gameStatus === GAME_STATUS.IS_PLAYING && isMobile && renderMobileButtons()}
            </div>

            {
                gameStatus === GAME_STATUS.FINISHED &&
                <div className="finished-content">
                    <p className="title"><span style={{ color: '#FFC2F5' }}>Woo!</span> It's the end of the game! Did you have a good convo?</p>
                    <p className="body">
                        Whether it was inspiring, awkward, or eye-opening, we hope everyone got to know one another just a little bit better. Please share Convos with your friends if you enjoyed it, and if you have feedback, we would love to hear it at hello@lovebybloom.com.
                    </p>
                    <div className="cta-buttons">
                        <a className="play-again" href="/?currentLevel=1">
                            <span>Play again</span>
                        </a>
                        <a
                            className="get-physical-deck"
                            href="https://forms.gle/KcE7j4bLX3Kn5Rd37"
                            target="_blank"
                            rel="noreferrer"
                        >Get physical deck</a>
                    </div>
                    <div className="footer">
                        <a href="https://www.instagram.com/lovebybloom/ ">
                            <UilInstagram color={'white'} />
                        </a>
                        <a href="https://www.facebook.com/lovebybloom">
                            <UilFacebookF color={'white'} />
                        </a>
                        <a href="https://twitter.com/lovebybloom">
                            <UilTwitterAlt color={'white'} />
                        </a>
                    </div>
                    {!isMobile && <div className="success-graphic" />}
                </div>
            }
        </div>
    );
};

export default withRouter(HomePage);
