import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import HomePage from './pages/HomePage';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <nav></nav>
          <Switch>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
      </div>
    </Router>
  );
}

export default App;
