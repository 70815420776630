import React, { useState } from 'react';

import { UilInfoCircle } from '@iconscout/react-unicons';
import { UilTimes } from '@iconscout/react-unicons';
import { UilFacebookF } from '@iconscout/react-unicons';
import { UilInstagram } from '@iconscout/react-unicons';
import { UilTwitterAlt } from '@iconscout/react-unicons';

import './styles/Help.scss';

const Help = () => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const toggleOverlayVisiblity = () => {
        setIsOverlayVisible(isOverlayVisible => !isOverlayVisible);
    };

    if (isOverlayVisible) {
        return (
            <div className="overlay">
                <button className="close" onClick={toggleOverlayVisiblity}>
                    <UilTimes color={'black'} />
                </button>
                <p className="info-text">
                    Convos is a game created by the makers of <a href="https://www.lovebybloom.com" style={{ color: '#9CA6FF' }}>bloom</a>, a modern dating app for serious relationships. Our mission is to help people build deeper, more genuine connections in a busy world.
                </p>
                <p className="info-text">
                    To play, open up Convos on your phone when you’re out on the town or share your screen during the Zoom calls with your friends. Bring on the exciting conversations. 
                </p>
                <p className="contact-text">Have some feedback or new card questions for us? Reach out at <span style={{ 'text-decoration': 'underline' }}>hello@convosbybloom.com</span>
                </p>
                <div className="footer">
                    <a href="https://www.instagram.com/lovebybloom/ ">
                        <UilInstagram color={'black'} />
                    </a>
                    <a href="https://www.facebook.com/lovebybloom">
                        <UilFacebookF color={'black'} />
                    </a>
                    <a href="https://twitter.com/lovebybloom">
                        <UilTwitterAlt color={'black'} />
                    </a>
                </div>
            </div>
        );
    };

    return (
        <div>
            <button className="Help" onClick={toggleOverlayVisiblity}>
                <UilInfoCircle color={'white'} />
            </button>
        </div>
    );
};

export default Help;