import './styles/LoadingIcon.scss';

const LoadingIcon = () => {
    return (
        <div className="LoadingIcon">
            <svg width="118" height="54" viewBox="0 0 118 54" fill="none">
                <g opacity="0.15" filter="url(#filter0_d)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25 0C11.1929 0 0 11.1929 0 25V46C0 48.2091 1.79086 50 4 50H25H59H93C106.807 50 118 38.8071 118 25C118 11.1929 106.807 0 93 0H25Z" fill="white"/>
                </g>
                <defs>
                <filter id="filter0_d" x="0" y="0" width="118" height="54" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.21275 0 0 0 0 0.1625 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                </defs>
            </svg>
            <div class="ball-loader">
                <div class="ball-loader-ball ball1"></div>
                <div class="ball-loader-ball ball2"></div>
                <div class="ball-loader-ball ball3"></div>
            </div>
        </div>
    );
};

export default LoadingIcon;
