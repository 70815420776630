import React, { useEffect, useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import './styles/QuestionCard.scss';

const LightTooltip = withStyles((theme) => ({
    "@global": {
        "@keyframes bounce": {
            '0%': {
                '-webkit-transform': 'translateY(0)',
            },
            '25%': {
                '-webkit-transform': 'translateY(-10px)',
            },
            '50%': {
                '-webkit-transform': 'translateY(-20px)',
            },
            '75%': {
                '-webkit-transform': 'translateY(-10px)',
            },
            '100%': {
                '-webkit-transform': 'translateY(0)',
            },
        },
    },
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
      animation: "bounce 2s ease-in-out infinite",
      backgroundColor: theme.palette.common.white,
      color: 'black',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      borderRadius: '30px',
      fontFamily: 'Overpass, sans-serif',
      marginBottom: '30px',
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '8px',
      paddingBottom: '8px',
      fontWeight: '700',
      
    },
}))(Tooltip);

const QuestionCard = ({
    questionText,
    levelText, color,
    onSwapClick,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    isTooltipHidden,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    useEffect(() => {
        setIsTooltipVisible(true);
        setTimeout(() => { setIsTooltipVisible(false) }, 5000);
    }, []);
    
    return (
        <LightTooltip arrow title="Tap to shuffle" placement="top" open={isTooltipVisible && !isTooltipHidden}>
            <div
                className="QuestionCard"
                style={{ 'background': color }}
                onMouseEnter={() => setIsTooltipVisible(true)}
                onMouseLeave={() => setIsTooltipVisible(false)}
                onMouseUp={() => {
                    if (onSwapClick) {
                        onSwapClick();
                    }
                    if (window.innerWidth < 600) {
                        setIsTooltipVisible(false);
                    }
                }}
                onTouchEnd={onTouchEnd}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
            >
                <p className="question-text">{questionText}</p>
                <div className="divider" />
                <p className="level-text">Level {levelText}</p>
            </div>
        </LightTooltip>
    );
};

export default QuestionCard;