import './styles/Logo.scss';

type Props = {
    color?: string,
    text: string,
};

const Logo = ({ color = '#9d95df', text }: Props) => {
    return (
        <div className="Logo">
            <a className="bubble" href="/">
                <p>Convos</p>
            </a>
            <a className="text" href="http://www.lovebybloom.com">by bloom</a>
        </div>
    );
};

export default Logo;